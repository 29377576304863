import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";

const EnergyFuture = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Products" },
    { label: "Energy Future" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Energy Future" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Futures</span>
                  <h2 className="theme-title">
                    OTC Energy Futures: Trade CFDs on Energy Futures
                  </h2>
                  <p className="theme-description">
                    The futures market allows trading specific quantities of
                    commodities or financial instruments under predefined terms,
                    including quantity, delivery time, and location, as
                    established by a central financial exchange. Unlike spot
                    trading, futures contracts involve a fixed delivery date in
                    the future.
                  </p>
                  <p className="theme-description">
                    When trading energy futures, the price is determined at the
                    time the contract is agreed upon, with the energy product
                    delivered on the pre-set delivery date. Delivery is
                    mandatory unless an offsetting trade with differing
                    specifications is arranged for the original position.
                  </p>
                  <p className="theme-description">
                    In contrast to traditional energy futures trading, OTC
                    Energy Futures or CFDs on Energy Futures operate over the
                    counter (OTC). Similar to spot FX trading, OTC transactions
                    occur directly between the two parties involved, bypassing
                    the central exchange market. This approach offers greater
                    flexibility and accessibility for traders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>benefits</span>
                  <h2 className="theme-title">
                    Benefits of CFDs in Energy Futures Trading:
                  </h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Direct Transactions:</b> Trade without the need
                              for intermediaries.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>High Liquidity:</b>Access a highly liquid
                              market for efficient entry and exit.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Complimentary Tools:</b> Benefit from free news
                              updates, charts, and market analysis.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Bidirectional Opportunities:</b> Potential to
                              profit in both rising and falling markets.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Leverage Options:</b> Gain high exposure with
                              minimal capital investment.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Risk-Free Practice:</b> Test your strategies
                              with a free demo account.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyFuture;
