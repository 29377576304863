import React from "react";
import PageTitle from "../components/common/PageTitle";

const TradingGlossary = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Resources" },
    { label: "Trading Glossary" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Trading Glossary" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <div className="currency-pairs">
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Term</th>
                            <th>Definition</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Ask Price</td>
                            <td>
                              The price at which a seller is willing to sell an
                              asset.
                            </td>
                          </tr>
                          <tr>
                            <td>Bid Price</td>
                            <td>
                              The price at which a buyer is willing to purchase
                              an asset.
                            </td>
                          </tr>
                          <tr>
                            <td>Bear Market</td>
                            <td>
                              A market condition characterized by falling
                              prices.
                            </td>
                          </tr>
                          <tr>
                            <td>Bull Market</td>
                            <td>
                              A market condition characterized by rising prices.
                            </td>
                          </tr>
                          <tr>
                            <td>Candlestick Chart</td>
                            <td>
                              A type of chart used in technical analysis to
                              display price movements.
                            </td>
                          </tr>
                          <tr>
                            <td>Leverage</td>
                            <td>
                              Using borrowed capital to amplify potential
                              returns.
                            </td>
                          </tr>
                          <tr>
                            <td>Market Order</td>
                            <td>
                              An order to buy or sell at the best available
                              price.
                            </td>
                          </tr>
                          <tr>
                            <td>Pip</td>
                            <td>
                              The smallest price move in Forex trading,
                              typically the fourth decimal.
                            </td>
                          </tr>
                          <tr>
                            <td>Spread</td>
                            <td>
                              The difference between the bid and ask prices.
                            </td>
                          </tr>
                          <tr>
                            <td>Stop-Loss Order</td>
                            <td>
                              An order to limit losses by selling at a
                              predetermined price.
                            </td>
                          </tr>
                          <tr>
                            <td>Volatility</td>
                            <td>A measure of price fluctuation over time.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingGlossary;
