import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";

const PreciousMetal = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Products" },
    { label: "Precious Metal" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Precious Metal" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Trading</span>
                  <h2 className="theme-title">
                    Precious Metals Trading with Upforex.com
                  </h2>
                  <p className="theme-description">
                    Trading in the over-the-counter (OTC) market spot precious
                    metals and contracts for difference (CFDs) on these metals,
                    providing traders opportunities to profit from price
                    changes. This allows them to speculate on the market by
                    either going long (investing in price increases) or going
                    short (speculating on price decreases) without needing to
                    own the actual precious metal. Much like in Forex trading,
                    transactions are conducted directly between the two parties,
                    eliminating the necessity for intermediaries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>benefits</span>
                  <h2 className="theme-title">
                    Benefits of Trading CFDs on Spot Precious Metals
                  </h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Absence of middlemen
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Trade without commission fees
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Effective capital utilization
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Ability to hedge other investments
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            No expiration dates
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Potential for profit in both up and down markets
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Access to high leverage
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Complimentary demo account
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Free market news, charts, and analysis
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Adjustable contract sizes
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>trade</span>
                <h2 className="theme-title">
                  Why Trade Spot Metals with UPFOREX?
                </h2>
                <p className="theme-description">
                  UPFOREX facilitates over-the-counter trading for spot precious
                  metals, such as gold and silver, with prices quoted per troy
                  ounce in Euro and USD. Our sophisticated platforms allow you
                  to trade spot gold and silver efficiently, featuring narrow
                  spreads, rapid execution, and ample liquidity. This setup lets
                  you profit from market price movements, whether they rise
                  (going long) or fall (going short), without needing to own the
                  metal itself.
                </p>
              </div>
            </div>
          </div>
          <div className="advantage-list">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Fixed Tight Spreads</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">No Commissions</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Quick Execution Without Re-quotes
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    High Liquidity and Depth of Market
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">No Expiration Dates</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Trade Conveniently from PC or Mobile
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Option to Utilize Expert Advisors
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Trade Freely Without Limitations
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Complimentary VPS Access</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">High Leverage Available</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Micro Lot Trading</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Swap-Free Options Available</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreciousMetal;
