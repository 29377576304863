import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";

const MetaTrader4 = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Platforms" },
    { label: "Meta Trader 4" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Meta Trader 4" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Trading</span>
                  <h2 className="theme-title">
                    The World's Favorite Forex Trading Platform
                  </h2>
                  <p className="theme-description">
                    MetaTrader 4 (MT4) is a top-tier platform designed for forex
                    trading, financial market analysis, and leveraging expert
                    advisors.
                  </p>
                  <p className="theme-description">
                    <b>Key Features:</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Access real-time financial market data
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Seamless mobile trading for trading on the go
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Trading signals and built-in tools to optimize
                              your strategies
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              User-friendly interface ideal for traders of all
                              experience levels
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaTrader4;
