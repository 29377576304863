import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import Contact from "../assets/images/contact.jpg";
import imageShape from "../assets/images/image-shape.png";

const ContactUs = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Contact Us" }];
  return (
    <div className="contact-page">
      <PageTitle title="Contact Us" breadcrumbs={breadcrumbs} />
      <div className="contact-top-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center page-center">
                <span>Contact</span>
                <h2 className="theme-title">Contact Us</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="contact-top">
                <div className="icon-box">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="content-box">
                  <h4 className="theme-title">VISIT OUR OFFICE</h4>
                  <p className="theme-description">Satrio Kuningan, Jakarta</p>
                </div>
                <div className="big-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="contact-top">
                <div className="icon-box">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="content-box">
                  <h4 className="theme-title">halo@company.com</h4>
                  <p className="theme-description">For Public Inquiries</p>
                </div>
                <div className="big-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="contact-top">
                <div className="icon-box">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="content-box">
                  <h4 className="theme-title">(888)234-5686</h4>
                  <p className="theme-description">MON - FRI, 9AM - 5PM</p>
                </div>
                <div className="big-icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-area page-padding border-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center page-center">
                <span>Have a questions?</span>
                <h2 className="theme-title">Let's get in touch</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="left-contact">
                <div className="theme-input-box">
                  <label>Full Name</label>
                  <div className="input-box">
                    <input
                      type="text"
                      className="form-control theme-input"
                      placeholder="Enter Full Name"
                    />
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                </div>
                <div className="theme-input-box">
                  <label>Email</label>
                  <div className="input-box">
                    <input
                      type="email"
                      className="form-control theme-input"
                      placeholder="Enter Email"
                    />
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                </div>
                <div className="theme-input-box">
                  <label>Subject</label>
                  <div className="input-box">
                    <input
                      type="text"
                      className="form-control theme-input"
                      placeholder="Enter Subject"
                    />
                    <FontAwesomeIcon icon={faPen} />
                  </div>
                </div>
                <div className="theme-input-box">
                  <label>Message</label>
                  <div className="input-box">
                    <textarea
                      cols="8"
                      rows="4"
                      className="form-control theme-input pl-4"
                      placeholder="Enter Message"
                    ></textarea>
                  </div>
                </div>
                <div className="btn-box mt-3">
                  <button type="button" className="theme-btn">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact-main">
                <div className="left-about">
                  <img src={Contact} alt="contact" />
                  <img src={imageShape} alt="contact" className="shape-top" />
                  <img
                    src={imageShape}
                    alt="contact"
                    className="shape-bottom"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
