import React from "react";

const PageTitle = ({ title, breadcrumbs }) => {
  return (
    <div className="breadcrumb-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="breadcrumb-title clearfix">
              <div className="heading">
                <div className="theme-title">{title}</div>
              </div>
              <div className="breadcumb-link">
                <ul>
                  {breadcrumbs?.map((breadcrumb, index) => (
                    <li key={index}>
                      {breadcrumb.link ? (
                        <a href={breadcrumb.link} className="text-link">
                          {breadcrumb.label}
                        </a>
                      ) : (
                        breadcrumb.label
                      )}
                      {index < breadcrumbs.length - 1 && (
                        <span className="slash-breacumb"> / </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
