import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";

const UpforexAgent = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Partnership" },
    { label: "Upforex Agents" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Upforex Agents" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Partnership</span>
                  <h2 className="theme-title">
                    Partnership Opportunities with UPFOREX
                  </h2>
                  <p className="theme-description">
                    Partner with UPFOREX, an award-winning global financial
                    service provider, and start earning as a certified Marketing
                    Agent. Whether you’re an individual or a company, our
                    Marketing Agent program offers a lucrative opportunity to
                    expand your revenue by referring new clients to trade with
                    UPFOREX.
                  </p>
                  <p className="theme-description">
                    <b>What You’ll Gain as an UPFOREX Marketing Agent</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Access to our award-winning reporting portal for
                              transparent tracking of client trades and payouts
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Ongoing support from a dedicated global team
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Comprehensive tools and resources, including
                              multilingual marketing materials
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Competitive benefits to help you succeed
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Customizable rebate schemes
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Unlimited commission potential
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">Instant rebate payouts</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Custom registration tracking links
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">Quick onboarding process</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Fast and secure funding methods
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Choose</span>
                  <h2 className="theme-title">Why Choose UPFOREX?</h2>
                  <p className="theme-description">
                    UPFOREX has been a global leader in the financial services
                    industry, offering:
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Superior trading conditions with low spreads,
                              ultra-fast execution, and flexible leverage
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Cutting-edge platforms like MetaTrader 4,
                              MetaTrader 5, and cTrader
                            </div>
                          </li>
                          <p className="theme-description">
                            A diverse portfolio of trading products, including:
                          </p>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">US Stocks</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">Precious Metals</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">Spot Forex</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">Index Futures</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">Energy Futures</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpforexAgent;
