import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";

const UsStocks = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Products" },
    { label: "US Stocks" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="US Stocks" breadcrumbs={breadcrumbs} />
      <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>advantages</span>
                <h2 className="theme-title">
                  Advantages of Trading US Stocks with Upforex.com
                </h2>
                <p className="theme-description">
                  Trading stocks, or equities, involves the buying and selling
                  of shares in publicly listed companies globally. This includes
                  well-known firms like Microsoft, Boeing, and Pfizer, as well
                  as any other public company on the stock exchange. At UPFOREX,
                  we facilitate US stock trading by focusing on a select group
                  of companies to broaden our users' financial prospects.
                </p>
                <p className="theme-description">
                  Unlike trading contracts for difference (CFDs), stocks serve
                  as financial instruments that enable investors to capitalize
                  on rising (going long) or falling (going short) prices without
                  owning the stock. Similar to foreign exchange (FX) trading,
                  these transactions are carried out over the counter (OTC),
                  allowing them to take place directly between the two involved
                  parties, bypassing a central exchange market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>benefits</span>
                  <h2 className="theme-title">
                    Benefits of CFDs in Stock Trading
                  </h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Absence of middlemen- Capital is utilized
                            efficiently
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Allows for hedging against other investments
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            No expiration dates
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Opportunity to profit in both falling and rising
                            markets
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            High levels of leverage
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Access to a free demo account
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Complimentary news, charts, and analysis
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>Trade</span>
                <h2 className="theme-title">
                  Why Trade OTC US Stocks with UPFOREX?
                </h2>
                <p className="theme-description">
                  UPFOREX provides OTC trading across a range of US stocks,
                  enabling you to predict the direction of a particular stock's
                  price without actually purchasing the stock itself. Our
                  service guarantees a quick and straightforward process while
                  eliminating the necessity of going through a broker.
                </p>
                <p className="theme-description">
                  With a CFD contract, instead of trading based on each pip in
                  the stock's price, you are buying or selling a set amount of
                  CFDs, which is similar to dealing with a certain quantity of
                  shares. It's worth noting that no actual share exchange occurs
                  during this transaction.
                </p>
              </div>
            </div>
          </div>
          <div className="advantage-list">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Quick transaction process</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">No brokers involved</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Optimized capital usage</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Accessible trading on both computer and mobile devices
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Ability to offset other investments
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">High leverage available</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Options to go long or short</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsStocks;
