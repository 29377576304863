import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";

const IndexFuture = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Products" },
    { label: "Index Future" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Index Future" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Futures</span>
                  <h2 className="theme-title">
                    OTC Stock Indices Futures: CFDs on Stock Indices Futures
                    (Index Futures)
                  </h2>
                  <p className="theme-description">
                    Indices trading involves monitoring the performance of
                    stocks, bonds, mutual funds, and other investments linked to
                    index trading, including the indices themselves. Unlike
                    trading based on individual stocks, index trading reflects
                    the overall market trends, which can be more appealing to
                    traders than focusing on single stocks.
                  </p>
                  <p className="theme-description">
                    Contracts for difference (CFDs) on indices, distinct from
                    traditional index trading and over-the-counter (OTC) index
                    trading, are financial tools that enable traders to profit
                    from price movements. Traders can benefit whether the prices
                    rise (by going long) or fall (by going short), all without
                    using the central exchange market.
                  </p>
                  <p className="theme-description">
                    Similar to foreign exchange (FX) trading, these transactions
                    are conducted over the counter (OTC), meaning they occur
                    directly between the involved parties without the need for
                    intermediaries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>benefits</span>
                  <h2 className="theme-title">
                    Benefits of Trading CFDs on Index Futures:
                  </h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Direct Market Access:</b> Trade without
                              involving intermediaries.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Capital Efficiency:</b> Maximize returns with
                              minimal capital investment.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Risk Management:</b> Use CFDs to hedge against
                              other portfolio investments.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Dual Market Opportunities:</b> Potential to
                              profit in both rising and falling markets.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Leverage Options:</b> Access high leverage for
                              amplified exposure.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Practice Environment:</b> Try strategies
                              risk-free with a demo account.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>trade</span>
                <h2 className="theme-title">
                  Why Choose UPFOREX for OTC Index Futures Trading?
                </h2>
                <p className="theme-description">
                  UPFOREX provides OTC trading on a wide range of major index
                  futures, enabling you to speculate on the performance of
                  specific index futures without relying on the central exchange
                  market. With access to indices through UPFOREX, you can engage
                  with the stocks of over 600 global companies.
                </p>
                <p className="theme-description">
                  Trading CFDs on index futures with UPFOREX is quick, seamless,
                  and eliminates the need for third-party intermediaries. This
                  gives you the flexibility to invest in a broad segment of a
                  national market or even an entire share market index, allowing
                  you to trade on the overall movement of a country’s market.
                </p>
                <p className="theme-description">
                  Additionally, all futures transactions are executed
                  electronically, removing the need for physical exchanges.
                </p>
                <p className="theme-description">
                  <b>Key Features and Benefits:</b>
                </p>
              </div>
            </div>
          </div>
          <div className="advantage-list">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Fast Execution:</b> Enjoy rapid and reliable trade
                    processing.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Capital Efficiency:</b> Maximize your investment
                    potential with optimized capital usage.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Cross-Platform Access:</b> Trade conveniently from both
                    PC and mobile devices.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Portfolio Protection:</b> Use trading strategies to hedge
                    existing investments.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>High Leverage:</b> Amplify your market exposure with
                    minimal capital.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Flexibility to Go Long or Short:</b> Profit from both
                    rising and falling markets.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="page-title mt-3">
                  <p className="theme-description">
                    With UPFOREX, you gain access to a versatile and efficient
                    trading platform designed to cater to your needs in the
                    fast-paced world of index futures trading.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Trading</span>
                  <h2 className="theme-title">
                    Major Index Futures Available for OTC Trading:
                  </h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Dow Jones Industrial Average
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">Mini Dow Jones Futures</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">E-mini NASDAQ Futures</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">E-mini S&P 500 Futures</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">FTSE 100 Index</div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">DAX Index</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexFuture;
