import React from "react";
import PageTitle from "../components/common/PageTitle";

const AntiMoneyLaundering = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Anti Money Laundering" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Anti Money Laundering" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Money Laundering</span>
                  <h2 className="theme-title">
                    Anti-Money Laundering (AML) Policy Statement
                  </h2>
                  <p className="theme-description">Issued by UpForex Ltd.</p>
                  <p className="theme-description">
                    UpForex Ltd. is fully committed to the prevention of money
                    laundering and the financing of terrorism. We adhere to
                    international standards and applicable legal requirements to
                    detect, deter, and report suspicious financial activities.
                  </p>
                  <p className="theme-description">
                    <b>Our Commitments</b>
                  </p>
                  <p className="theme-description">
                    <b>1. Compliance with Regulations:</b>
                  </p>
                  <p className="theme-description">
                    UpForex Ltd. strictly complies with all anti-money
                    laundering (AML) and counter-terrorist financing (CTF)
                    regulations in the jurisdictions in which we operate.
                  </p>
                  <p className="theme-description">
                    <b>2. Know Your Customer (KYC) Procedures:</b>
                  </p>
                  <p className="theme-description">
                    We require all clients to complete a thorough verification
                    process, including providing valid identification documents,
                    proof of address, and other necessary information.
                  </p>
                  <p className="theme-description">
                    Enhanced due diligence is applied for higher-risk clients,
                    transactions, or jurisdictions.
                  </p>
                  <p className="theme-description">
                    <b>3. Monitoring and Reporting:</b>
                  </p>
                  <p className="theme-description">
                    All transactions are monitored for suspicious activity,
                    including irregular trading patterns and large or unusual
                    withdrawals or deposits.
                  </p>
                  <p className="theme-description">
                    Any suspicious transactions are promptly reported to the
                    relevant authorities as required by law.
                  </p>
                  <p className="theme-description">
                    <b>4. Risk-Based Approach:</b>
                  </p>
                  <p className="theme-description">
                    We apply a risk-based approach to AML/CTF compliance by
                    assessing the potential risks associated with client
                    profiles, jurisdictions, and transactions.
                  </p>
                  <p className="theme-description">
                    <b>5. Employee Training:</b>
                  </p>
                  <p className="theme-description">
                    All employees are regularly trained to identify, prevent,
                    and report suspicious activities in accordance with AML
                    regulations.
                  </p>
                  <p className="theme-description">
                    <b>Client Responsibilities</b>
                  </p>
                  <p className="theme-description">
                    Clients must ensure that all funds deposited into their
                    trading accounts are derived from legitimate sources.
                  </p>
                  <p className="theme-description">
                    It is strictly prohibited to use UpForex accounts for
                    illegal activities, including money laundering or terrorism
                    financing.
                  </p>
                  <p className="theme-description">
                    <b>Zero Tolerance Policy</b>
                  </p>
                  <p className="theme-description">
                    UpForex Ltd. has a zero-tolerance policy toward money
                    laundering and reserves the right to refuse or terminate
                    accounts where activities are suspected to contravene
                    AML/CTF regulations.
                  </p>
                  <p className="theme-description">
                    <b>Safeguarding the Financial System</b>
                  </p>
                  <p className="theme-description">
                    Our commitment to AML compliance is part of a broader effort
                    to maintain the integrity of the financial system and to
                    ensure a safe and transparent trading environment for all
                    our clients.
                  </p>
                  <p className="theme-description">
                    By using our services, clients acknowledge and agree to
                    comply with UpForex Ltd.’s AML policies and procedures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AntiMoneyLaundering;
