import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const TestimonialCarousel = ({ Carousel, settings, isHomePage = false }) => {
  return (
    <Slider {...settings}>
      {Carousel.map((testimonial, index) => (
        <div className="slider-outer" key={index}>
          {isHomePage ? (
            <div className="Trader-box">
              <div className="data-box">
                <p className="theme-description">{testimonial.description}</p>
                <div className="bottom-testimonial">
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    className="small-quote"
                  />
                  <h4 className="theme-title">{testimonial.name}</h4>
                  <span>{testimonial.location}</span>
                </div>
              </div>
              <div className="img-box">
                <img src={testimonial.image} alt="avatar" />
              </div>
              <FontAwesomeIcon icon={faQuoteRight} className="big-icon" />
            </div>
          ) : (
            <div className="promotion-slider">
              <div className="image-outer">
                <div className="image">
                  <img src={testimonial.image} alt="avatar" />
                </div>
                <div className="content">
                  <h2 className="theme-title">{testimonial.heading}</h2>
                  <button type="button" className="btn">
                    {testimonial.button}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialCarousel;
