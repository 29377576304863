import React from "react";
import PageTitle from "../components/common/PageTitle";

const RiskDisclosure = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Risk Disclosure" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Risk Disclosure" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Disclosure</span>
                  <h2 className="theme-title">Risk Disclosure Statement</h2>
                  <p className="theme-description">Issued by UpForex Ltd.</p>
                  <p className="theme-description">
                    <b>Introduction</b>
                  </p>
                  <p className="theme-description">
                    Trading in leveraged financial instruments such as Forex,
                    CFDs, indices, and cryptocurrencies involves significant
                    risk and is not suitable for all investors. Before engaging
                    in trading activities, it is important to carefully consider
                    your financial objectives, level of experience, and risk
                    tolerance.
                  </p>
                  <p className="theme-description">
                    <b>Key Risk Factors</b>
                  </p>
                  <p className="theme-description">
                    <b>1. Market Risk</b>
                  </p>
                  <p className="theme-description">
                    The value of financial instruments can fluctuate
                    significantly due to market conditions, economic events, and
                    geopolitical developments. Price movements can result in
                    substantial losses or gains.
                  </p>
                  <p className="theme-description">
                    <b>2. Leverage Risk</b>
                  </p>
                  <p className="theme-description">
                    Trading on margin amplifies both potential profits and
                    losses. While leverage offers opportunities for higher
                    returns, it also increases the risk of losing more than your
                    initial investment.
                  </p>
                  <p className="theme-description">
                    <b>3. Volatility Risk</b>
                  </p>
                  <p className="theme-description">
                    Markets can be highly volatile, especially in instruments
                    such as Forex and cryptocurrencies. Sudden and unpredictable
                    price movements can lead to rapid losses.
                  </p>
                  <p className="theme-description">
                    <b>4. Liquidity Risk</b>
                  </p>
                  <p className="theme-description">
                    Under certain market conditions, it may be difficult or
                    impossible to execute trades at your desired price due to
                    low market liquidity.
                  </p>
                  <p className="theme-description">
                    <b>5. Counterparty Risk</b>
                  </p>
                  <p className="theme-description">
                    Transactions executed through UpForex involve counterparty
                    risk, including the potential inability of UpForex or its
                    liquidity providers to fulfill contractual obligations.
                  </p>
                  <p className="theme-description">
                    <b>6. Technology Risk</b>
                  </p>
                  <p className="theme-description">
                    Online trading platforms are subject to risks such as system
                    failures, connectivity issues, and cyber threats, which may
                    result in delays or inability to execute trades.
                  </p>
                  <p className="theme-description">
                    <b>7. Regulatory Risk</b>
                  </p>
                  <p className="theme-description">
                    Changes in regulations or restrictions imposed by
                    governments or regulatory authorities may impact your
                    trading activities.
                  </p>
                  <p className="theme-description">
                    <b>Client Responsibility</b>
                  </p>
                  <p className="theme-description">
                    Clients should fully understand the risks involved in
                    trading leveraged instruments and seek independent financial
                    advice if necessary.
                  </p>
                  <p className="theme-description">
                    It is the responsibility of the client to monitor their
                    trading positions and ensure adequate margin levels are
                    maintained.
                  </p>
                  <p className="theme-description">
                    <b>No Guarantees</b>
                  </p>
                  <p className="theme-description">
                    UpForex Ltd. does not guarantee profits or the avoidance of
                    losses. Past performance is not indicative of future
                    results.
                  </p>
                  <p className="theme-description">
                    <b>Disclaimer</b>
                  </p>
                  <p className="theme-description">
                    By trading with UpForex Ltd., you acknowledge that you have
                    read, understood, and accepted the risks associated with
                    trading leveraged financial instruments. You agree to bear
                    full responsibility for any losses incurred and waive any
                    liability on the part of UpForex Ltd.
                  </p>
                  <p className="theme-description">
                    <b>Conclusion</b>
                  </p>
                  <p className="theme-description">
                    Trading leveraged products carries a high level of risk and
                    may not be suitable for all investors. Ensure that you fully
                    understand the risks and trade responsibly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskDisclosure;
