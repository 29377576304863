import React from "react";
import PageTitle from "../components/common/PageTitle";

const PrivacyPolicy = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Privacy Policy" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Privacy Policy" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Policy</span>
                  <h2 className="theme-title">Privacy Policy</h2>
                  <p className="theme-description">
                    Effective Date: 1st Dec 2024 
                  </p>
                  <p className="theme-description">
                    Welcome to Upforex.com or Up Forex Ltd (“Upforex”, “we”,
                    “us”, or “our”, "Upforex Ltd"). Your privacy is important to
                    us. This Privacy Policy explains how we collect, use,
                    disclose, and safeguard your information when you use our
                    services, including our website and trading platforms
                    (collectively, the “Services”). By accessing or using our
                    Services, you agree to this Privacy Policy. If you do not
                    agree, please refrain from using our Services.
                  </p>
                  <p className="theme-description">
                    <b>1. Information We Collect</b>
                  </p>
                  <p className="theme-description">
                    We may collect the following types of information:
                  </p>
                  <div className="sub-section ps-3">
                    <p className="theme-description">
                      <b>1.1 Personal Information:</b>
                    </p>
                    <p className="theme-description">
                      Name, contact details (email address, phone number,
                      physical address).
                    </p>
                    <p className="theme-description">
                      Identification documents (passport, national ID, or
                      driver’s license).
                    </p>
                    <p className="theme-description">
                      Financial information (bank account details, trading
                      history).
                    </p>
                    <p className="theme-description">
                      <b>1.2 Non-Personal Information:</b>
                    </p>
                    <p className="theme-description">
                      Device information (IP address, browser type, operating
                      system).
                    </p>
                    <p className="theme-description">
                      Usage data (pages visited, time spent on our site,
                      referral sources).
                    </p>
                    <p className="theme-description">
                      <b>1.3 Cookies and Similar Technologies:</b>
                    </p>
                    <p className="theme-description">
                      We use cookies and similar technologies to enhance your
                      experience, analyze traffic, and provide targeted
                      advertising. You can manage your cookie preferences
                      through your browser settings.
                    </p>
                  </div>
                  <p className="theme-description">
                    <b>2. How We Use Your Information</b>
                  </p>
                  <p className="theme-description">
                    We use the collected information for the following purposes:
                  </p>
                  <p className="theme-description">
                    To provide and maintain our Services.
                  </p>
                  <p className="theme-description">
                    To verify your identity and comply with legal obligations
                    (e.g., KYC, AML).
                  </p>
                  <p className="theme-description">
                    To process transactions and communicate with you about your
                    account.
                  </p>
                  <p className="theme-description">
                    To improve our Services and understand user behavior.
                  </p>
                  <p className="theme-description">
                    To send promotional materials and marketing communications
                    (with your consent).
                  </p>
                  <p className="theme-description">
                    To detect, prevent, and address fraud, security breaches, or
                    technical issues.
                  </p>
                  <p className="theme-description">
                    <b>3. How We Share Your Information</b>
                  </p>
                  <p className="theme-description">
                    We do not sell or rent your personal information. However,
                    we may share your information with:
                  </p>
                  <p className="theme-description">
                    <b>Service Providers:</b> Third-party companies that assist
                    in delivering our Services (e.g., payment processors, cloud
                    service providers).
                  </p>
                  <p className="theme-description">
                    <b>Regulatory Authorities:</b> To comply with legal
                    obligations or regulatory requirements.
                  </p>
                  <p className="theme-description">
                    <b>Business Partners:</b> For joint marketing initiatives
                    (only with your consent).
                  </p>
                  <p className="theme-description">
                    <b>Legal Proceedings:</b> If required by law or to protect
                    our rights and interests.
                  </p>
                  <p className="theme-description">
                    <b>4. Data Security</b>
                  </p>
                  <p className="theme-description">
                    We implement industry-standard security measures to protect
                    your personal information. However, no method of
                    transmission over the Internet or electronic storage is
                    completely secure. We encourage you to take precautions to
                    protect your information.
                  </p>
                  <p className="theme-description">
                    <b>5. International Data Transfers</b>
                  </p>
                  <p className="theme-description">
                    Your information may be transferred to and processed in
                    countries outside your country of residence, where data
                    protection laws may differ. We ensure such transfers comply
                    with applicable laws to safeguard your data.
                  </p>
                  <p className="theme-description">
                    <b>6. Your Rights</b>
                  </p>
                  <p className="theme-description">
                    Depending on your jurisdiction, you may have the following
                    rights:
                  </p>
                  <p className="theme-description">
                    Access, correct, or delete your personal information.
                  </p>
                  <p className="theme-description">
                    Restrict or object to the processing of your data.
                  </p>
                  <p className="theme-description">
                    Withdraw consent where processing is based on consent.
                  </p>
                  <p className="theme-description">
                    File a complaint with a data protection authority.
                  </p>
                  <p className="theme-description">
                    To exercise these rights, contact us at [Insert Contact
                    Email].
                  </p>
                  <p className="theme-description">
                    <b>7. Retention of Information</b>
                  </p>
                  <p className="theme-description">
                    We retain your information for as long as necessary to
                    fulfill the purposes outlined in this Privacy Policy or
                    comply with legal obligations.
                  </p>
                  <p className="theme-description">
                    <b>8. Third-Party Links</b>
                  </p>
                  <p className="theme-description">
                    Our Services may contain links to third-party websites. We
                    are not responsible for the privacy practices of those
                    websites. We encourage you to review their privacy policies.
                  </p>
                  <p className="theme-description">
                    <b>9. Updates to This Policy</b>
                  </p>
                  <p className="theme-description">
                    We may update this Privacy Policy from time to time. Any
                    changes will be posted on this page with an updated
                    effective date. Your continued use of our Services
                    constitutes your acceptance of the revised policy.
                  </p>
                  <p className="theme-description">
                    <b>10. Contact Us</b>
                  </p>
                  <p className="theme-description">
                    If you have questions or concerns about this Privacy Policy,
                    please contact us at:
                  </p>
                  <p className="theme-description">
                    Email: support@upforex.com
                  </p>
                  <p className="theme-description">
                    Address: Ground Floor, The Sotheby Building, Rodney Village,
                    Rodney Bay, Gros-Islet, Saint Lucia.
                  </p>
                  <p className="theme-description">
                    Thank you for trusting Upforex with your personal
                    information. Your privacy matters to us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
