import React, { useState } from "react";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("tab1"); // State to track active tab

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update the active tab
  };

  return (
    <div className="tabs-container">
      {/* Tab Headers */}
      <div className="tabs-header">
        <button
          className={`tab-button ${activeTab === "tab1" ? "active" : ""}`}
          onClick={() => handleTabClick("tab1")}
        >
          Forex
        </button>
        <button
          className={`tab-button ${activeTab === "tab2" ? "active" : ""}`}
          onClick={() => handleTabClick("tab2")}
        >
          Indices
        </button>
        <button
          className={`tab-button ${activeTab === "tab3" ? "active" : ""}`}
          onClick={() => handleTabClick("tab3")}
        >
          Commodities
        </button>
        <button
          className={`tab-button ${activeTab === "tab4" ? "active" : ""}`}
          onClick={() => handleTabClick("tab4")}
        >
          Metals
        </button>
        <button
          className={`tab-button ${activeTab === "tab5" ? "active" : ""}`}
          onClick={() => handleTabClick("tab5")}
        >
          Cryptocurrencies
        </button>
        <button
          className={`tab-button ${activeTab === "tab6" ? "active" : ""}`}
          onClick={() => handleTabClick("tab6")}
        >
          Stocks
        </button>
      </div>

      {/* Tab Content */}
      <div className="tabs-content">
        {activeTab === "tab1" && (
          <div className="tab-content">
            <div className="products-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Full Name</th>
                      <th>Notional Value of contract size</th>
                      <th>Quote precision</th>
                      <th>Margin required</th>
                      <th>Minimum Order Size in Units</th>
                      <th>Minimum Transaction Step</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AUDCAD</td>
                      <td>Australian Dollar vs Canadian Dollar</td>
                      <td>AUD 100 000</td>
                      <td>0.00001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>AUDCHF</td>
                      <td>Australian Dollar vs Swiss Franc</td>
                      <td>AUD 100 000</td>
                      <td>0.00001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>AUDJPY</td>
                      <td>Australian Dollar vs Japanese Yen</td>
                      <td>AUD 100 000</td>
                      <td>0.001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>AUDNZD</td>
                      <td>Australian Dollar vs New Zealand Dollar</td>
                      <td>AUD 100 000</td>
                      <td>0.00001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sub-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Re-Open</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sunday</td>
                      <td>23:00</td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Monday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Wednessday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab2" && (
          <div className="tab-content">
            <div className="products-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Full Name</th>
                      <th>Notional Value of contract size</th>
                      <th>Quote precision</th>
                      <th>Margin required</th>
                      <th>Minimum Order Size in Units</th>
                      <th>Minimum Transaction Step</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AUS200</td>
                      <td>ASX 200</td>
                      <td>Instrument level * USD 25</td>
                      <td>1</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>GER30</td>
                      <td>DAX 30</td>
                      <td>Instrument level * EUR 25</td>
                      <td>0.1</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>EU50</td>
                      <td>Euro Stoxx 50</td>
                      <td>Instrument level * EUR 10</td>
                      <td>0.001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>FRA40</td>
                      <td>CAC 40</td>
                      <td>Instrument level * EUR 10</td>
                      <td>0.00001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sub-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Re-Open</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sunday</td>
                      <td>23:00</td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Monday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Wednessday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab3" && (
          <div className="tab-content">
            <div className="products-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Full Name</th>
                      <th>Notional Value of contract size</th>
                      <th>Quote precision</th>
                      <th>Margin required</th>
                      <th>Minimum Order Size in Units</th>
                      <th>Minimum Transaction Step</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BRENT</td>
                      <td>UK Oil</td>
                      <td>Instrument level * USD 1000</td>
                      <td>0.01</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>COCOA</td>
                      <td>Cocoa</td>
                      <td>Instrument level * USD 100</td>
                      <td>1</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>COFFEE</td>
                      <td>Coffee</td>
                      <td>Instrument level * USD 2000</td>
                      <td>0.01</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>CORN</td>
                      <td>Corn</td>
                      <td>Instrument level * USD 500</td>
                      <td>0.00001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sub-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Re-Open</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sunday</td>
                      <td>23:00</td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Monday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Wednessday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab4" && (
          <div className="tab-content">
            <div className="products-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Full Name</th>
                      <th>Notional Value of contract size</th>
                      <th>Quote precision</th>
                      <th>Margin required</th>
                      <th>Minimum Order Size in Units</th>
                      <th>Minimum Transaction Step</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ALUMINIUM</td>
                      <td>Aluminium</td>
                      <td>Instrument level * USD 50</td>
                      <td>1</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>COPPER</td>
                      <td>Copper</td>
                      <td>Instrument level * USD 30</td>
                      <td>1</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>XAGUSD</td>
                      <td>Silver troy ounce</td>
                      <td>Instrument level * USD 5000</td>
                      <td>0.001</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>XAUUSD</td>
                      <td>Gold troy ounce</td>
                      <td>Instrument level * USD 100</td>
                      <td>0.01</td>
                      <td>1%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sub-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Re-Open</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sunday</td>
                      <td>23:00</td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Monday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Wednessday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab5" && (
          <div className="tab-content">
            <div className="products-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Full Name</th>
                      <th>Notional Value of contract size</th>
                      <th>Quote precision</th>
                      <th>Margin required</th>
                      <th>Minimum Order Size in Units</th>
                      <th>Minimum Transaction Step</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ADAUSD</td>
                      <td>Cardano vs US Dollar</td>
                      <td>10000 ADA</td>
                      <td>0.0001</td>
                      <td>20%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>AVEUSD</td>
                      <td>Aave vs US Dollar</td>
                      <td>10 AVE</td>
                      <td>0.01</td>
                      <td>20%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>BCHUSD</td>
                      <td>Bitcoin Cash vs US Dollar</td>
                      <td>10 BCH</td>
                      <td>0.01</td>
                      <td>20%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                    <tr>
                      <td>BSVUSD</td>
                      <td>Bitcoin Cash SV vs US Dollar</td>
                      <td>10 BSV</td>
                      <td>0.01</td>
                      <td>20%</td>
                      <td>0.01</td>
                      <td>0.01</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sub-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Re-Open</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sunday</td>
                      <td>23:00</td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Monday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Wednessday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab6" && (
          <div className="tab-content">
            <div className="products-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Full Name</th>
                      <th>Notional Value of contract size</th>
                      <th>Quote precision</th>
                      <th>Margin required</th>
                      <th>Minimum Order Size in Units</th>
                      <th>Minimum Transaction Step</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AAL.US</td>
                      <td>American Airlines Group Inc.</td>
                      <td>Instrument level * USD 1</td>
                      <td>0.01</td>
                      <td>10%</td>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>ABT.US</td>
                      <td>Abbot Laboratories</td>
                      <td>Instrument level * USD 1</td>
                      <td>0.01</td>
                      <td>10%</td>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>ADBE.US</td>
                      <td>Adobe Systems Inc.</td>
                      <td>Instrument level * USD 1</td>
                      <td>0.01</td>
                      <td>10%</td>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>AIG.US</td>
                      <td>American International Group</td>
                      <td>Instrument level * USD 1</td>
                      <td>0.01</td>
                      <td>10%</td>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sub-tabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Re-Open</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sunday</td>
                      <td>23:00</td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Monday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                    <tr>
                      <td>Wednessday</td>
                      <td></td>
                      <td>22:00</td>
                      <td>23:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="page-title mt-3">
        <p className="theme-description">
          <b>Note:</b> Futures instruments are not included in the standard
          offering and are only available if specific exchange permissions are
          granted. Please ensure that you have the necessary permissions before
          attempting to access or trade these instruments.
        </p>
      </div>
    </div>
  );
};

export default Tabs;
