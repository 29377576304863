import React from "react";
import PageTitle from "../components/common/PageTitle";
import Tabs from "./common/tabs";

const ContractSpecification = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Products" },
    { label: "Contract Specification" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Contract Specification" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <Tabs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractSpecification;
