import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import AboutImg from "../assets/images/about-2.jpg";

const AboutUs = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "About Us" }];
  return (
    <div className="about-page">
      <PageTitle title="About Us" breadcrumbs={breadcrumbs} />
      <div className="about-info-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-about">
                <img src={AboutImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-about">
                <div className="page-title mb-4">
                  <span>About Us</span>
                  <h2 className="theme-title">Welcome to Upforex.com</h2>
                  <p className="theme-description">
                    Welcome to Upforex.com, a leading Forex brokerage dedicated
                    to equipping traders worldwide with the tools they need. We
                    offer exceptional knowledge, and an extensive selection of
                    trading accounts tailored for Retail Traders, Money
                    Managers, Institutional Traders, Risk Managers, and
                    Investment Companies. At Upforex.com, your experience goes
                    beyond trading; you gain entry to a reliable platform
                    crafted to uphold the highest standards in security,
                    transparency, and performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-choose-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center page-center">
                <span>Trusted Choice</span>
                <h2 className="theme-title">Why Choose Upforex.com?</h2>
                <p className="theme-description">
                  Dedicated to delivering excellence every single time.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faDesktop} />
                </div>
                <h4 className="theme-title">Ultra-Modern Trading Platform</h4>
                <p className="theme-description mb-0">
                  Our ultra-modern platform provides powerful analytical tools,
                  live data feeds, and ability to access from various devices,
                  ensuring an exceptional trading experience.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faHeadset} />
                </div>
                <h4 className="theme-title">Dedicated Support</h4>
                <p className="theme-description mb-0">
                  Our dedicated team of specialists is available 24/7, offering
                  insights and support whenever you need assistance.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <h4 className="theme-title">User Friendly Account Options</h4>
                <p className="theme-description mb-0">
                  We offer solutions designed to meet various trading
                  requirements, whether you're a beginner retail trader or a
                  seasoned institutional investor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
