import React from "react";
import ResponsiveDropdownMenu from "./ResponsiveDropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
const Header = () => {
  return (
    <header>
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="left-topbar">
                <ul className="p-0 m-0">
                  <li>
                    <FontAwesomeIcon icon={faPhone} />
                    +888 666 000
                  </li>
                  <li className="email-div">
                    <FontAwesomeIcon icon={faEnvelope} />
                    info@upforex.com
                  </li>
                  <li>
                    <div className="mobile-social-icons">
                      <ul className="p-0 m-0">
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faFacebookF} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faTwitter} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faInstagram} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faLinkedin} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="right-topbar">
                <ul className="p-0 m-0">
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ResponsiveDropdownMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
