import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="footer-box">
              <div className="img-box">
                <img src={Logo} alt="logo" />
              </div>
              <p className="theme-description">
                UpForex is your gateway to global markets—where smart trading
                begins!
              </p>
              <div className="social-links">
                <ul className="p-0">
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="footer-box">
              <h4 className="theme-title">Home</h4>
              <div className="links-box">
                <ul className="p-0">
                  <li>
                    <Link to="/about-us">
                      <FontAwesomeIcon icon={faAngleRight} />
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/us-stocks">
                      <FontAwesomeIcon icon={faAngleRight} />
                      US Stocks
                    </Link>
                  </li>
                  <li>
                    <Link to="/platforms/mt5">
                      <FontAwesomeIcon icon={faAngleRight} />
                      MetaTrader 5 (MT5)
                    </Link>
                  </li>
                  <li>
                    <Link to="/accounts">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Accounts
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="footer-box">
              <h4 className="theme-title">Useful Links</h4>
              <div className="links-box">
                <ul className="p-0">
                  <li>
                    <Link to="/risk-disclosure">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Risk Disclosure
                    </Link>
                  </li>
                  <li>
                    <Link to="/anti-money-laundering">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Prevent Money Laundering
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="footer-box">
              <h4 className="theme-title">Education</h4>
              <div className="links-box">
                <ul className="p-0">
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Education
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Basic course
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faAngleRight} />
                      About academy
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faAngleRight} />
                      Terms & Condition
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
